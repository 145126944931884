/*
    Imports
*/
import { Container } from '@material-ui/core';
import { useParams, useNavigate } from 'react-router-dom';
import Page from 'src/components/Page';
import { useEffect, useState } from 'react';
import { fNumber, toWords } from 'src/utils/formatNumber';
import Logo from '../components/Logo';
import CenterLoading from 'src/components/misc/CenterLoading';
import { SETTINGS } from 'src/config/settings';
import paymentsService from 'src/services/PaymentsServiceClass';
import userService from 'src/services/UserService';
import productsService from 'src/services/ProductsServiceClass';
import slipsService from 'src/services/SlipsServiceClass';
import commissionsService from 'src/services/CommissionServiceClass';
import expenseService from 'src/services/ExpenseServiceClass';

/*
    Main Working
*/
export default ({ payment, slip, commission, expense }) => {
    const paymentID = useParams().paymentID;
    const navigate = useNavigate();

    const [details, setDetails] = useState();
    const [biller, setBiller] = useState();
    const [user, setUser] = useState();
    const [products, setProducts] = useState([]);

    const getData = () => {
        productsService
            .getAll()
            .then((res) => {
                setProducts(res);
                let service = paymentsService;
                if (slip) service = slipsService;
                if (commission) service = commissionsService;
                if (expense) service = expenseService;
                service
                    .getOne(paymentID)
                    .then((res) => {
                        setDetails(res);

                        userService
                            .getUser(res.added_by)
                            .then((r) => {
                                console.log(r);
                                setBiller(r);
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                        if (res.member)
                            userService.getUser(res.member).then((r) => {
                                setUser(r);
                            });
                    })
                    .catch((error) => {
                        if (error.response) if (error.response.status === 401) navigate('/401');
                        // navigate('/404');
                    });
            })
            .catch((error) => {
                if (error.response) if (error.response.status === 401) navigate('/401');
                // navigate('/404');
            });
    };

    const loading = !details;

    const B = (props) => <span style={{ fontWeight: 'bold' }}>{props.children}</span>;

    useEffect(getData, []);

    /*
      Main Design
    */

    return (
        <Page title="Print Bill">
            {loading ? (
                <Container style={{ height: '100vh' }}>
                    <CenterLoading />
                </Container>
            ) : (
                <Container maxWidth="xl">
                    {/* <ListPageTitle>
                    {"Printing Bill"}
                </ListPageTitle> */}
                    {/* <AddProductForm editing={editing} /> */}
                    <style>{`
                    table{
                        border:1px solid black;
                        width: 100%;
                        border-collapse: collapse;
                        margin-top: 30px;
                    }
                  
                    table th {
                        border:1px solid black;
                    }
                    table tr {
                        border:1px solid black;
                    }
                    table td {
                        border:1px solid black;
                    }
                    .details tr{
                        border:1px solid transparent;
                    }
                    .details td{
                        border:1px solid transparent;
                        width: 70%;
                    }  
                    .details th{
                        border:1px solid transparent;
                    } 
                    .padding{
                        padding: 10px
                    }
                    .paddingS td, th{
                        padding: 5px
                    }
                `}</style>

                    <div>
                        <div style={{ marginTop: 50 }}>
                            <Logo />
                        </div>
                        <table className="details">
                            <tbody>
                                <tr>
                                    <td>
                                        <B>Payment ID:</B> {details?.id}
                                    </td>
                                    <td>
                                        <B>Added By:</B> {biller?.name} ({biller?.id})
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <B>Date: </B> {details?.date.slice(0, 10)}
                                    </td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>

                        <table className="details">
                            <tbody>
                                <tr>
                                    <td>
                                        <B>Type: </B> {details?.type}
                                    </td>
                                    {slip && (
                                        <td>
                                            <B>Bank/Cash: </B> {details?.details}
                                        </td>
                                    )}
                                    {payment && (
                                        <td>
                                            <B>Payment For: </B> {user?.name} ({user?.id})
                                        </td>
                                    )}
                                    {commission && (
                                        <td>
                                            <B>Commission For: </B> {details?.details}
                                        </td>
                                    )}
                                    {expense && (
                                        <td>
                                            <B>Expense For: </B> {details?.details}
                                        </td>
                                    )}
                                </tr>
                            </tbody>
                        </table>

                        {details?.note && (
                            <table className="details">
                                <tbody>
                                    <tr>
                                        <td>
                                            <B>Note: </B> {details?.note}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        )}

                        {details?.extras &&
                            Array.isArray(details?.extras) &&
                            details?.extras.length > 0 && (
                                <table>
                                    <tbody>
                                        <tr className="paddingS">
                                            <th>Sr.</th>
                                            <th>Product</th>
                                            <th>Amount</th>
                                            <th>Expense</th>
                                        </tr>
                                        {details?.extras.map((a, index) => {
                                            return (
                                                <tr className="paddingS" key={index + 'table_item'}>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        {
                                                            products.find((p) => p.id === a.product)
                                                                ?.name
                                                        }
                                                    </td>
                                                    <td>
                                                        Rs.{' '}
                                                        {a.amount > 1
                                                            ? fNumber(+a.amount)
                                                            : a.amount}
                                                    </td>
                                                    <td>
                                                        Rs.{' '}
                                                        {a.expense > 1
                                                            ? fNumber(+a.expense)
                                                            : a.expense}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            )}
                        <table>
                            <tbody>
                                <tr className="paddingS">
                                    <th>Total</th>
                                </tr>
                                <tr>
                                    <th>
                                        Rs.{' '}
                                        {details?.amount > 1
                                            ? fNumber(+details?.amount)
                                            : details?.amount}
                                    </th>
                                </tr>
                                <tr>
                                    <th>{toWords.convert(details?.amount)}</th>
                                </tr>
                            </tbody>
                        </table>
                        <div style={{ marginTop: 50, textAlign: 'center' }}>
                            Software Developed and Provided by Arose Niazi
                            <a
                                style={{ textDecoration: 'none' }}
                                href={SETTINGS.eastdevsLink.link}
                                target="blank"
                            >
                                {' '}
                                ({SETTINGS.eastdevsLink.name}){' '}
                            </a>
                            Contact: {SETTINGS.contacts.arose}
                        </div>
                    </div>
                </Container>
            )}
        </Page>
    );
};
